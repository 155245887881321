import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { searchReducer } from './reducers/searchReducer';
import { userSigninReducer } from './reducers/userReducer';

function getInitialUserInfoCheck() {
	let userLocal = localStorage.getItem('userInfo')
		? JSON.parse(localStorage.getItem('userInfo'))
		: null;
	if (userLocal) {
		if (userLocal.access_token) {
			const currentTime = new Date();
			const expiryTime = new Date(userLocal.access_token_expiracy);
			if (currentTime.valueOf() > expiryTime.valueOf()) {
				localStorage.removeItem('userInfo');
				userLocal = null;
			}
		} else if (userLocal.refresh_token) {
			const currentTime = new Date();
			const expiryTime = new Date(userLocal.refresh_token_expiracy);
			if (currentTime.valueOf() > expiryTime.valueOf()) {
				localStorage.removeItem('userInfo');
				userLocal = null;
			} else {
				//TODO: action to refresh the token
			}
		} else {
			localStorage.removeItem('userInfo');
			userLocal = null;
		}
	}
	return userLocal;
}

const initialState = {
	userSignin: {
		userInfo: getInitialUserInfoCheck(),
	},
	searchResult: {
		result: {
			result_header: {},
			records: [],
		},
	},
};
const reducer = combineReducers({
	userSignin: userSigninReducer,
	searchResult: searchReducer,
});
const composeEnhacer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
	reducer,
	initialState,
	composeEnhacer(applyMiddleware(thunk))
);

export default store;
